<template>
  <el-drawer
    :visible.sync="drawer"
    :wrapperClosable="false"
    :with-header="false"
    direction="rtl"
    size="80%"
    :destroy-on-close="true"
  >
    <el-container style="height:100%">
      <el-header height="auto">
        <fw-header titles="默认封面" />
      </el-header>
      <el-main>
        <el-row>
          <el-col style="margin-top: 10px" :span="24">
            <vxe-table
              class="mytable-scrollbar"
              stripe
              highlight-hover-row
              size="small"
              :border="false"
              align="center"
              ref="xTable1"
              :data="cover_list"
              row-key
            >
              <vxe-column type="seq" title="序号" width="50" align="center"></vxe-column>
              <vxe-column min-width="100">
                <template #header>
                  <p>素材类型</p>
                </template>
                <template #default="{ row }">
                  <p :title="row.name" class="ellipsisText">
                    {{ row.name || "- -" }}
                  </p>
                </template>
              </vxe-column>
              <vxe-column min-width="200" align="center">
                <template #header>
                  <p>默认封面</p>
                </template>
                <template #default="{ row }">
                  <fwuploadphoto
                    style="justify-content: center;margin-left:10px"
                    :max="1"
                    @postImgMsg="val => postImgMsg(val, row)"
                    :backShow="row.my_sucai_type_default_cover.cover_url ? [row.backShow] : []"
                  />
                </template>
              </vxe-column>
              <vxe-column min-width="100">
                <template #header>
                  <p>创建人</p>
                </template>
                <template #default="{ row }">
                  <p :title="row.created_by" class="ellipsisText">
                    {{ row.my_sucai_type_default_cover.created_by || "- -" }}
                  </p>
                </template>
              </vxe-column>
              <vxe-column min-width="100">
                <template #header>
                  <p>创建时间</p>
                </template>
                <template #default="{ row }">
                  <p :title="row.created_at" class="ellipsisText">
                    {{ row.my_sucai_type_default_cover.created_at || "- -" }}
                  </p>
                </template>
              </vxe-column>
              <vxe-column min-width="100">
                <template #header>
                  <p>更新人</p>
                </template>
                <template #default="{ row }">
                  <p :title="row.created_by" class="ellipsisText">
                    {{ row.my_sucai_type_default_cover.updated_by || "- -" }}
                  </p>
                </template>
              </vxe-column>
              <vxe-column min-width="100">
                <template #header>
                  <p>更新时间</p>
                </template>
                <template #default="{ row }">
                  <p :title="row.created_at" class="ellipsisText">
                    {{ row.my_sucai_type_default_cover.updated_at || "- -" }}
                  </p>
                </template>
              </vxe-column>
            </vxe-table>
          </el-col>
        </el-row>
      </el-main>
      <el-footer style="margin: 15px 0">
        <el-row>
          <el-col align="center" :span="24">
            <span class="chongzhiPlain">
              <el-button style="width: 88px" size="small" @click="drawer = false">取消</el-button>
            </span>
          </el-col>
        </el-row>
      </el-footer>
    </el-container>
  </el-drawer>
</template>
<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      drawer: false,
      callback: null,
      cover_list: [], //表格数据
    };
  },
  created() {},
  mounted() {},
  methods: {
    open(val, callback) {
      console.log(val);
      this.callback = callback;
      this.drawer = true;
      this.query();
    },
    postImgMsg(val, row) {
      if (row.my_sucai_type_default_cover.id == 0) {
        let parmas = {
          sucai_type_id: row.my_sucai_type_default_cover.sucai_type_id,
          cover_url: val[0].image_url,
        };
        console.log(parmas);
        this.$axios.postJSON("/sucai_manage/user/cover/create", parmas).then(res => {
          if (res.code == 200) {
            this.$message.success("设置成功!");
            this.query();
          }
        });
      } else {
        let parmas = {
          id: row.my_sucai_type_default_cover.id,
          sucai_type_id: row.my_sucai_type_default_cover.sucai_type_id,
          cover_url: val[0].image_url,
        };
        console.log(parmas);
        this.$axios.putJSON("/sucai_manage/user/cover/update", parmas).then(res => {
          if (res.code == 200) {
            this.$message.success("设置成功!");
            this.query();
          }
        });
      }
    },
    query() {
      this.$axios.get("/sucai_manage/user/cover/list").then(res => {
        if (res.code == 200) {
          console.log(res);
          this.cover_list = res.data;
          this.cover_list.forEach(i => {
            i["backShow"] = {
              attachment_id: 0,
              full_name: "",
              image_save_url: "",
              image_url: i.my_sucai_type_default_cover.cover_url,
              size: 111,
              suffix: ".png",
            };
          });
          console.log(this.cover_list);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-drawer__body {
  padding-left: 25px;
  padding-right: 25px;
}
</style>
