<template>
  <div>
    <headerCode />
    <div class="menu-info-box">
      <div class="menu-info">
        <span v-for="(it, index) in pageNav" :key="'pagenav' + index">
          {{ it.name }}
          <template v-if="index < pageNav.length - 1">></template>
        </span>
      </div>
    </div>
    <!-- 基础信息 -- 目前只有2部分，所以可以放这里，后期有改动  -->
    <!-- <userInfoCom /> -->
    <div class="breadcrumb" style="width:1200px; margin:0 auto">
      用户中心 /
      <span style="color:#000; font-weight: bolder;">我的素材</span>
    </div>
    <div id="main_SourceMaterial" style="width:1200px; margin:0 auto">
      <el-container style="height:100%">
        <el-header height="auto">
          <fw-header titles="我的素材" />
          <fw-nav :array="array" :type="type" @choseNav="choseNav" />
          <fw-serchHeader :model="inputs" @search="searList" ref="searchHeaders" />
          <el-row class="dialog_b" style="margin-bottom:15px">
            <el-col :span="20" class="tags_bar" align="left" v-if="!showMores">
              <el-col
                :span="3"
                v-for="(t, i) in tagsList.slice(0, 6)"
                :key="i"
                style="min-width:15%"
                class="chose_tag"
              >
                <p :class="t.selected == 1 ? 'chosed' : ''" @click="searchTags(t)">
                  {{ t.name }}({{ t.count }})
                </p>
              </el-col>
            </el-col>
            <el-col :span="20" class="tags_bar" align="left" style="flex-wrap: wrap;" v-else>
              <el-col
                :span="3"
                v-for="(t, i) in tagsList"
                :key="i"
                class="more_tags"
                @click="searchTags(t, i)"
              >
                <p :class="t.selected == 1 ? 'chosed' : ''" @click="searchTags(t)">
                  {{ t.name }}({{ t.count }})
                </p>
              </el-col>
            </el-col>
            <el-col :span="2" v-if="tagsList.length > 6">
              <el-link
                @click="showMores = !showMores"
                style="padding: 0 10px"
                :underline="false"
                type="primary"
              >
                查看全部
                <i :class="showMores ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
              </el-link>
            </el-col>
            <el-col :span="2" v-else><span style="color: transparent">1</span></el-col>
            <el-col class="tags_bar" align="right" :span="2">
              <div style=" cursor: pointer;" @click="setAllTags">
                <i class="el-icon-s-tools"></i>
                标签管理
              </div>
            </el-col>
          </el-row>
        </el-header>
        <!-- 操作按钮 -->
        <el-row class="dialog_c" style="margin-bottom:15px">
          <el-col :span="12" align="left" class="dialog_d">
            <el-checkbox v-model="allChecked" v-if="listType == 1" @change="allChecked_btn">
              全选
            </el-checkbox>
            <span class="checked_">(已选:{{ checked }})</span>
            <!-- 删除 -->
            <div class="icons">
              <span title="删除" @click="dels" class="el-icon-delete-solid"></span>
            </div>
            <!-- 清除标签 -->
            <div class="icons">
              <span
                title="清除标签"
                class="el-icon-brush"
                style="transform: rotateX(180deg);"
                @click="setTags(0)"
              ></span>
            </div>
            <!-- 打标签 -->
            <div class="icons">
              <span title="打标签" class="el-icon-price-tag" @click="setTags(1)"></span>
            </div>
            <div class="icons">
              <span title="共享" class="el-icon-share" @click="shareBtn"></span>
            </div>
          </el-col>
          <el-col :span="12" align="right" class="right_btn">
            <div style=" cursor: pointer; margin-right:15px" @click="setDefault">
              <i class="el-icon-s-tools"></i>
              默认封面设置
            </div>
            <el-button @click="addsc" type="primary" size="small" style="margin-right:20px">
              上传素材
            </el-button>
            <span @click="choseType(2)" :class="['iconsd1', listType == 2 ? 'ischose' : '']">
              <i class="el-icon-s-unfold"></i>
            </span>
            <span @click="choseType(1)" :class="['iconsd2', listType == 1 ? 'ischose' : '']">
              <i class="el-icon-menu"></i>
            </span>
          </el-col>
        </el-row>
        <el-main class="mainsd">
          <div style="height: 100%; width: 100%">
            <el-container style="height:100%">
              <el-main>
                <div v-show="listType == 1" style="height:100%">
                  <picList ref="picList" :tableList="tableList" @getCheckedNum="getCheckedNum" />
                </div>
                <div v-show="listType == 2" style="height:100%">
                  <tableList :tableList="tableList" ref="tableList" @getListNum="getListNum" />
                </div>
              </el-main>
            </el-container>
          </div>
        </el-main>
        <el-footer>
          <fw-pageNumber align="right" :pages="pages" @changePage="changePage" />
        </el-footer>
      </el-container>
    </div>
    <!-- 新增 -->
    <addSucai ref="addSucai" />
    <!-- 标签 -->
    <tags ref="tags" @closeTag="closeTag" v-if="showTag" />
    <!-- 共享 -->
    <share ref="share" />
    <!-- 默认封面 -->
    <cover ref="cover" />
  </div>
</template>
<script>
import asideIcon from "@/components/asideIcon";
import userHeader from "@/components/header/userHeader";
import headerCode from "@/views/mySelf/components/header.vue";
import { mapGetters } from "vuex";
import tableList from "./components/tableList.vue";
import picList from "./components/picList.vue";
import addSucai from "./addSucai/addSucai.vue";
import tags from "./components/tags.vue";
import share from "./components/share.vue";
import cover from "./components/cover.vue";
export default {
  name: "",
  components: {
    asideIcon,
    userHeader,
    headerCode,
    tableList,
    picList,
    addSucai,
    tags,
    share,
    cover,
  },
  provide() {
    //注射给子组件方法
    return {
      query: this.query,
      getTagsList: this.getTagsList,
    };
  },
  computed: {
    ...mapGetters(["pageNav"]),
  },
  data() {
    return {
      array: [
        //切换卡
        {
          name: "全部",
          id: -1,
        },
        {
          name: "图片",
          id: 1,
        },
        {
          name: "音频",
          id: 2,
        },
        {
          name: "视频",
          id: 3,
        },
        {
          name: "文字",
          id: 4,
        },
        {
          name: "文件",
          id: 5,
        },
        {
          name: "图标",
          id: 6,
        },
        {
          name: "链接",
          id: 7,
        },
      ],
      type: -1, //切换卡里初始的位置
      pages: {
        //页码
        pageNum: 1,
        pageSize: 100,
        total: 0,
      },
      listType: 1, //列表格式
      searchData: {}, //搜索内容
      inputs: [
        //搜索框里内容
        {
          type: "input",
          field: "serial_number",
          label: "素材编号",
          value: "",
        },
        {
          type: "input",
          field: "title",
          label: "素材标题",
          value: "",
        },
        {
          type: "time",
          field: "updated_at",
          label: "更新时间",
          value: "",
        },
      ],
      allChecked: false, //全选框 初始未勾选
      checked: 0, //已选的数量
      /* showSet: true, */
      tableList: [], //列表数据
      tagsList: [], //标签数据
      showMores: false, //查看更多
      my_sucai_label_pivot_label_id: [], //标签筛选数组
      showTag: false,
    };
  },
  created() {
    this.$store.commit("changeChoseType", 4);
    this.query();
    this.getTagsList();
  },
  mounted() {},
  methods: {
    query() {
      let params = {
        sucai_type_id: [this.type == -1 ? null : this.type], // 导航选项卡的切换的参数
        my_sucai_label_pivot_label_id: this.my_sucai_label_pivot_label_id,
        ...this.searchData,
        page: this.pages.pageNum,
        page_size: this.pages.pageSize,
      };
      if (params.sucai_type_id[0] == null) {
        delete params.sucai_type_id;
      }
      if (this.my_sucai_label_pivot_label_id.length == 0) {
        delete params.my_sucai_label_pivot_label_id;
      }
      let api = "/sucai_manage/user/list";

      this.$axios.postJSON(api, params).then(res => {
        this.tableList = res.data.data.map(res => {
          res.selected = 0;
          return res;
        });
        this.pages.total = res.data.total;
        this.allChecked = false;
        this.checked = 0;
      });
    },
    getTagsList() {
      this.$axios.postJSON("/sucai_manage/user/label/all_list").then(res => {
        if (res.code == 200) {
          let arr = res.data.map(i => {
            i["selected"] = 0;
            return i;
          });
          this.tagsList = JSON.parse(JSON.stringify(arr));
          console.log(this.tagsList);
        }
      });
    },
    searchTags(item) {
      //搜索标签
      console.log(item);
      if (item.selected == 0) {
        item.selected = 1;
        this.my_sucai_label_pivot_label_id.push(item.id);
        console.log(this.my_sucai_label_pivot_label_id);
        this.query();
      } else {
        item.selected = 0;
        let index = this.my_sucai_label_pivot_label_id.findIndex(i => {
          return i == item.id;
        });
        console.log(index);
        this.my_sucai_label_pivot_label_id.splice(index, 1);
        console.log(this.my_sucai_label_pivot_label_id);
        this.query();
      }
    },
    addsc() {
      //新增
      this.$refs.addSucai.open({}, val => {
        console.log(val);
        this.query();
        this.getTagsList();
      });
    },
    choseType(val) {
      this.pages.pageSize = 100;
      this.pages.pageNum = 1;
      this.listType = val;
      this.query();
    },
    choseNav(val) {
      this.pages.pageSize = 10;
      this.pages.pageNum = 1;
      this.type = val;
      this.query();
      this.searchData = {};
    },
    searList(val) {
      this.searchData = val;
      this.query();
    },

    changePage(val) {
      //页码改变
      if (val.type == "size") {
        this.pages.pageSize = val.num;
      } else {
        this.pages.pageNum = val.num;
      }
      this.query();
      this.checked = 0;
      this.allChecked = false;
      this.$refs.tableList.records = [];
    },
    allChecked_btn(val) {
      if (this.listType == 1) {
        console.log(val);
        if (val) {
          this.tableList.forEach(i => {
            i.selected = 1;
          });
          let arr = this.tableList.filter(v => {
            return v.selected == 1;
          });
          this.checked = arr.length;
          /* this.showSet = false;
        this.$nextTick(() => {
          this.showSet = true;
        }); */
        } else {
          this.tableList.forEach(i => {
            i.selected = 0;
          });
          let arr = this.tableList.filter(v => {
            return v.selected == 1;
          });
          this.checked = arr.length;
          /* this.showSet = false;
        this.$nextTick(() => {
          this.showSet = true;
        }); */
        }
      }
    },
    dels() {
      let arr = [];
      if (this.listType == 1) {
        arr = this.tableList.filter(v => {
          return v.selected == 1;
        });
      } else {
        arr = this.$refs.tableList.records;
      }
      //删除
      if (arr.length == 0) {
        return this.$message.warning("请先勾选数据");
      } else {
        let id = [];
        arr.map(i => {
          return id.push(i.id);
        });
        this.$confirm("是否批量删除所勾选数据?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$axios.deltJSON("/sucai_manage/user/delete", { id: id }).then(res => {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.$refs.tableList.records = [];
              this.query();
              this.getTagsList();
            });
          })
          .catch(() => {});
      }
    },
    shareBtn(row) {
      //共享
      let arr = [];
      if (this.listType == 1) {
        arr = this.tableList.filter(v => {
          return v.selected == 1;
        });
      } else {
        arr = this.$refs.tableList.records;
      }
      if (arr.length == 0) {
        return this.$message.warning("请先勾选数据");
      } else {
        this.$refs.share.open({}, val => {
          console.log(val);
          let id = [];
          arr.map(i => {
            return id.push(i.id);
          });
          let company_ids = val.map(i => {
            return i.company_id;
          });
          let params = {
            id: id,
            company_ids: company_ids,
          };
          this.$axios.postJSON(`/sucai_manage/user/share`, params).then(res => {
            if (res.code == 200) {
              setTimeout(() => {
                this.$message.success("共享成功");
                this.query();
                this.getTagsList();
              }, 500);
            }
          });
        });
      }
    },
    setTags(num) {
      //0是批量清除 1是新增
      //批量清除标签
      let arr = []; //获取选取的数据
      if (this.listType == 1) {
        arr = this.tableList.filter(v => {
          return v.selected == 1;
        });
      } else {
        arr = this.$refs.tableList.records;
      }
      if (arr.length == 0) {
        return this.$message.warning("请先勾选数据");
      } else {
        if (num == 0) {
          let id = [];
          arr.map(i => {
            return id.push(i.id);
          });

          this.$axios.putJSON(`/sucai_manage/user/clear_label`, { id: id }).then(res => {
            if (res.code == 200) {
              setTimeout(() => {
                this.$message.success("清除成功");
                this.query();
                this.getTagsList();
              }, 500);
            }
          });
        } else {
          this.showTag = true;
          this.$nextTick(() => {
            this.$refs.tags.open({ is_all: true }, val => {
              console.log(val);
              let label_list = [];
              val.map(i => {
                let obj = {
                  label_id: i.id,
                  label_name: i.name,
                };
                return label_list.push(obj);
              });
              let id = [];
              arr.map(i => {
                return id.push(i.id);
              });
              let params = {
                id: id,
                label_list: label_list,
              };
              this.$axios.putJSON(`/sucai_manage/user/batch_label`, params).then(res => {
                if (res.code == 200) {
                  setTimeout(() => {
                    this.$message.success("设置成功");
                    this.query();
                    this.getTagsList();
                  }, 500);
                }
              });
            });
          });
        }
      }
    },
    closeTag(value) {
      this.showTag = value;
    },
    setAllTags() {
      //标签管理
      //setAllTags显示提交和多选框
      this.$refs.tags.open({ setAllTags: 1 }, val => {
        console.log(val);
      });
    },
    setDefault() {
      //默认设置封面
      this.$refs.cover.open();
    },
    getCheckedNum(num) {
      if (this.listType == 1) {
        this.checked = num;
        if (this.tableList.length == num) {
          this.allChecked = true;
        } else {
          this.allChecked = false;
        }
      }
    },
    getListNum(num) {
      if (this.listType != 1) {
        this.checked = num;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.breadcrumb {
  padding: 10px 50px;
}
#main_SourceMaterial {
  min-height: calc(100vh - 48px);
  margin: 0px 50px;
  padding: 0px 25px;
  padding-bottom: 1px;
  box-sizing: border-box;
  background-color: #fff;
}
.icons {
  cursor: pointer;
  border-radius: 3px;
  width: 20px;
  height: 20px;
  background-color: #4387f8;
  display: inline-block;
  margin-left: 10px;
  text-align: center;
  i,
  span {
    color: #fff;
    line-height: 20px;
    font-size: 14px;
  }
}
::v-deep .el-dialog {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0px !important;
}
.iconsd1 {
  display: inline-block;
  width: 25px;
  height: 25px;
  background-color: #fafafa;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  i {
    color: #ccc;
    font-size: 18px;
    text-align: center;
    line-height: 25px;
    width: 25px;
  }
  .radius2 {
    border-radius: 0px 5px 5px 0px;
  }
}
.iconsd2 {
  display: inline-block;
  width: 25px;
  height: 25px;
  background-color: #fafafa;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 0px 5px 5px 0px;
  i {
    color: #ccc;
    font-size: 18px;
    text-align: center;
    line-height: 25px;
    width: 25px;
  }
}
.checked_ {
  font-size: 12px;
  color: rgb(6, 188, 9);
}
.ischose {
  background-color: rgba(236, 242, 254, 1);
  i {
    color: #0088fe;
  }
}
.right_btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.dialog_b {
  display: flex;
  align-items: flex-start;
}
.dialog_c {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog_d {
  display: flex;
  align-items: center;
}
.mainsd {
  display: flex;
  height: calc(100vh - 350px);
}
.tags_bar {
  display: flex;
  align-items: center;
  // div:first-child {
  //   color: #000;
  //   font-weight: bolder;
  // }
}
.more_tags {
  min-width: 15%;
  margin-bottom: 6px;
  cursor: pointer;
}
.chose_tag {
  cursor: pointer;
}
.chosed {
  font-weight: bolder;
  color: #f56c6c;
}
</style>
