<template>
  <el-container style="height:100%">
    <el-main>
      <vxe-table
        max-height="100%"
        class="mytable-scrollbar"
        stripe
        highlight-hover-row
        size="small"
        ref="xTable1"
        :data="tableList"
        @checkbox-all="selectAllEvent"
        @checkbox-change="selectChangeEvent"
        :checkbox-config="{
          checkMethod: checCheckboxkMethod2,
        }"
        auto-resize
      >
        <vxe-column fixed="left" align="center" type="checkbox" width="50"></vxe-column>
        <vxe-column fixed="left" type="seq" title="序号" width="50" align="center"></vxe-column>
        <vxe-column title="素材封面" width="100" fixed="left">
          <template #header></template>
          <template #default="{ row }">
            <img
              v-if="row.cover.image_url"
              class="imgs"
              :src="row.cover.image_url"
              alt="图片加载出错"
            />
            <img v-else class="imgs" src="" alt="暂无图片" />
          </template>
        </vxe-column>
        <vxe-column min-width="160" fixed="left">
          <template #header>
            <p>素材编号</p>
            <p>素材标题</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText " :title="row.serial_number || '--'">
              {{ row.serial_number || "--" }}
            </p>
            <p class="ellipsisText" :title="row.title || '--'">
              {{ row.title || "--" }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="160">
          <template #header>
            <p>素材类型</p>
            <p>素材格式</p>
            <p>素材大小</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText" :title="sucai_type(row.sucai_type_id)">
              {{ sucai_type(row.sucai_type_id) }}
            </p>
            <p class="ellipsisText" :title="name(row)">
              {{ name(row) }}
            </p>
            <p class="ellipsisText" :title="getfilesize(row.sucai_size)">
              {{ getfilesize(row.sucai_size) }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="200">
          <template #header>
            <p>素材标签</p>
          </template>
          <template #default="{ row }">
            <span>
              <div class="tags_bar">
                <div v-for="(item, index) in row.my_su_cai_label_pivot" :key="index">
                  {{ item.label_name }}
                </div>
              </div>
            </span>
          </template>
        </vxe-column>
        <vxe-column min-width="140">
          <template #header>
            <p>更新时间</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText" :title="row.updated_at">{{ row.updated_at || "--" }}</p>
          </template>
        </vxe-column>
        <vxe-column width="120" fixed="right">
          <template #header><p>操作</p></template>
          <template #default="{ row }">
            <div>
              <el-link @click="prew(row)" class="links" :underline="false" type="primary">
                预览
              </el-link>
              <el-link @click="edit(row)" class="links" :underline="false" type="primary">
                编辑
              </el-link>
              <el-link class="links" :underline="false" type="primary" @click="setTags(row)">
                标签
              </el-link>
              <el-link class="links" :underline="false" type="primary" @click="shareBtn(row)">
                共享
              </el-link>
              <el-link @click="delets(row)" class="links" :underline="false" type="primary">
                删除
              </el-link>
            </div>
          </template>
        </vxe-column>
      </vxe-table>
    </el-main>

    <editSucai ref="editSucai" />
    <tags ref="tags" @closeTag="closeTag" v-if="showTag" />
    <share ref="share" />
    <message-box ref="messageBox" />
    <!-- <detailSucai ref="detailSucai" /> -->
  </el-container>
</template>
<script>
import editSucai from "../editSucai/editSucai.vue";
import share from "./share.vue";
import tags from "./tags.vue";
import MessageBox from "./messageBox.vue";
/* import detailSucai from "../details/details.vue"; */
export default {
  name: "",
  components: { editSucai, tags, share, MessageBox },
  props: {
    tableList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  inject: ["query", "getTagsList"],
  data() {
    return {
      records: [],
      showTag: false,
    };
  },
  created() {},
  mounted() {},
  methods: {
    getfilesize(size) {
      //把字节转换成正常文件大小
      if (size == "--") return "--";
      if (!size) return "--";
      var num = 1024.0; //byte
      if (size < num) return size + "kB";
      if (size < Math.pow(num, 2)) return (size / num).toFixed(2) + "MB"; //kb
      if (size < Math.pow(num, 3)) return (size / Math.pow(num, 2)).toFixed(2) + "G"; //M
      if (size < Math.pow(num, 4)) return (size / Math.pow(num, 3)).toFixed(2) + "T"; //G
    },
    selectAllEvent({ checked }) {
      const records = this.$refs.xTable1.getCheckboxRecords();
      this.records = records;
      let num = records.length;
      this.$emit("getListNum", num);
    },
    selectChangeEvent({ checked }) {
      const records = this.$refs.xTable1.getCheckboxRecords();
      this.records = records;
      let num = records.length;
      this.$emit("getListNum", num);
    },
    checCheckboxkMethod2({ row }) {
      return row.approval_status != 0;
    },
    name(row) {
      let nameStr = "";
      if (row.sucai_type_id == 4 || row.sucai_type_id == 7) {
        nameStr = "--";
      } else {
        nameStr = row.sucai_format;
      }
      return nameStr;
    },
    sucai_type(val) {
      let name = "";
      if (val == 1) {
        name = "图片";
      } else if (val == 2) {
        name = "音频";
      } else if (val == 3) {
        name = "视频";
      } else if (val == 4) {
        name = "文字";
      } else if (val == 5) {
        name = "文件";
      } else if (val == 6) {
        name = "图标";
      } else if (val == 7) {
        name = "链接";
      }
      return name;
    },
    group(arr) {
      let str = [];
      arr.map(i => {
        return str.push(i.group_name);
      });
      return str.join(";");
    },
    delets(row) {
      //删除
      let id = [];
      id.push(row.id);
      this.$confirm(" 是否删除此条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios.deltJSON("/sucai_manage/user/delete", { id: id }).then(res => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.query();
            this.getTagsList();
          });
        })
        .catch(() => {});
    },
    edit(row) {
      //编辑
      //console.log(this.$refs.xTable1);
      this.$refs.editSucai.open({ id: row.id }, val => {
        console.log(val);
        this.query();
        this.getTagsList();
      });
    },
    prew(item) {
      console.log(item);
      let type = item.sucai_type_id;
      let src = null;
      if (type == 5) {
        this.$axios
          .get("/base/file/public/preview", {
            attachment_id: item.file_info.attachment_id,
            model_name: "134",
          })
          .then(res => {
            localStorage.setItem("callbackHTML", res);
            window.open("/look.html");
          });
      } else if (type == 7) {
        let judge = item.content.includes("http");
        let url = "";
        if (judge) {
          url = item.content;
        } else {
          url = "http://" + item.content;
        }
        window.open(url);
      } else {
        if (type == 4) {
          // name = "文字";
          src = item.content;
        } else {
          // name = "图标";
          src = item.file_info;
        }
        this.$refs.messageBox.open({ src: src, type: type });
      }
    },
    setTags(row) {
      //标签

      this.showTag = true;
      this.$nextTick(() => {
        let arr = row.my_su_cai_label_pivot.map(i => {
          return i.label_id;
        });
        this.$refs.tags.open({ afterChose: arr }, val => {
          console.log(val);
          let label_list = [];
          val.map(i => {
            let obj = {
              label_id: i.id,
              label_name: i.name,
            };
            return label_list.push(obj);
          });

          let params = {
            id: [row.id],
            label_list: label_list,
          };
          this.$axios.putJSON(`/sucai_manage/user/batch_label`, params).then(res => {
            if (res.code == 200) {
              setTimeout(() => {
                this.$message.success("设置成功");
                this.query();
                this.getTagsList();
              }, 500);
            }
          });
        });
      });
    },
    closeTag(value) {
      this.showTag = value;
    },
    shareBtn(row) {
      let arr = [row];

      this.$refs.share.open({ selectNames: arr }, val => {
        console.log(val);
        let id = [row.id];
        let company_ids = val.map(i => {
          return i.company_id;
        });
        let params = {
          id: id,
          company_ids: company_ids,
        };
        this.$axios.postJSON(`/sucai_manage/user/share`, params).then(res => {
          if (res.code == 200) {
            setTimeout(() => {
              this.$message.success("共享成功");
              this.query();
              this.getTagsList();
            }, 500);
          }
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.imgs {
  width: 80px;
  height: 80px;
}
.links {
  margin: 0 5px;
}
.tags_bar {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  div {
    margin-right: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fef0f0;
    padding: 0 20px;
    border: 1px solid #fde2e2;
    min-width: 40px;
    border-radius: 15px;
    color: #f56c6c;
  }
}
</style>
