var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-container',{staticStyle:{"height":"100%"}},[_c('el-main',[_c('vxe-table',{ref:"xTable1",staticClass:"mytable-scrollbar",attrs:{"max-height":"100%","stripe":"","highlight-hover-row":"","size":"small","data":_vm.tableList,"checkbox-config":{
        checkMethod: _vm.checCheckboxkMethod2,
      },"auto-resize":""},on:{"checkbox-all":_vm.selectAllEvent,"checkbox-change":_vm.selectChangeEvent}},[_c('vxe-column',{attrs:{"fixed":"left","align":"center","type":"checkbox","width":"50"}}),_c('vxe-column',{attrs:{"fixed":"left","type":"seq","title":"序号","width":"50","align":"center"}}),_c('vxe-column',{attrs:{"title":"素材封面","width":"100","fixed":"left"},scopedSlots:_vm._u([{key:"header",fn:function(){return undefined},proxy:true},{key:"default",fn:function(ref){
      var row = ref.row;
return [(row.cover.image_url)?_c('img',{staticClass:"imgs",attrs:{"src":row.cover.image_url,"alt":"图片加载出错"}}):_c('img',{staticClass:"imgs",attrs:{"src":"","alt":"暂无图片"}})]}}])}),_c('vxe-column',{attrs:{"min-width":"160","fixed":"left"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("素材编号")]),_c('p',[_vm._v("素材标题")])]},proxy:true},{key:"default",fn:function(ref){
      var row = ref.row;
return [_c('p',{staticClass:"ellipsisText ",attrs:{"title":row.serial_number || '--'}},[_vm._v(" "+_vm._s(row.serial_number || "--")+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":row.title || '--'}},[_vm._v(" "+_vm._s(row.title || "--")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("素材类型")]),_c('p',[_vm._v("素材格式")]),_c('p',[_vm._v("素材大小")])]},proxy:true},{key:"default",fn:function(ref){
      var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":_vm.sucai_type(row.sucai_type_id)}},[_vm._v(" "+_vm._s(_vm.sucai_type(row.sucai_type_id))+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":_vm.name(row)}},[_vm._v(" "+_vm._s(_vm.name(row))+" ")]),_c('p',{staticClass:"ellipsisText",attrs:{"title":_vm.getfilesize(row.sucai_size)}},[_vm._v(" "+_vm._s(_vm.getfilesize(row.sucai_size))+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"200"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("素材标签")])]},proxy:true},{key:"default",fn:function(ref){
      var row = ref.row;
return [_c('span',[_c('div',{staticClass:"tags_bar"},_vm._l((row.my_su_cai_label_pivot),function(item,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(item.label_name)+" ")])}),0)])]}}])}),_c('vxe-column',{attrs:{"min-width":"140"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("更新时间")])]},proxy:true},{key:"default",fn:function(ref){
      var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.updated_at}},[_vm._v(_vm._s(row.updated_at || "--"))])]}}])}),_c('vxe-column',{attrs:{"width":"120","fixed":"right"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("操作")])]},proxy:true},{key:"default",fn:function(ref){
      var row = ref.row;
return [_c('div',[_c('el-link',{staticClass:"links",attrs:{"underline":false,"type":"primary"},on:{"click":function($event){return _vm.prew(row)}}},[_vm._v(" 预览 ")]),_c('el-link',{staticClass:"links",attrs:{"underline":false,"type":"primary"},on:{"click":function($event){return _vm.edit(row)}}},[_vm._v(" 编辑 ")]),_c('el-link',{staticClass:"links",attrs:{"underline":false,"type":"primary"},on:{"click":function($event){return _vm.setTags(row)}}},[_vm._v(" 标签 ")]),_c('el-link',{staticClass:"links",attrs:{"underline":false,"type":"primary"},on:{"click":function($event){return _vm.shareBtn(row)}}},[_vm._v(" 共享 ")]),_c('el-link',{staticClass:"links",attrs:{"underline":false,"type":"primary"},on:{"click":function($event){return _vm.delets(row)}}},[_vm._v(" 删除 ")])],1)]}}])})],1)],1),_c('editSucai',{ref:"editSucai"}),(_vm.showTag)?_c('tags',{ref:"tags",on:{"closeTag":_vm.closeTag}}):_vm._e(),_c('share',{ref:"share"}),_c('message-box',{ref:"messageBox"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }