<template>
  <el-container style="height:100%">
    <el-main>
      <div class="box_bar">
        <div
          v-for="(item, index) in tableList"
          :key="index"
          :class="['box', item.selected == 1 ? 'chosed' : '']"
        >
          <div>
            <i v-if="item.selected == 1" class="el-icon-check icons1"></i>
            <div class="cover" @click="choseOne(item)">
              <el-image
                style="width: 100%; height: 100%"
                :src="item.cover.image_url"
                fit="contain"
                v-if="item.cover.image_url"
              ></el-image>
              <img src="@/assets/img/pure.jpg" v-else style="width: 100%; height: 100%" />
            </div>
            <div style="padding:5px 10px">
              <div class="number">
                <div
                  class="ellipsisText"
                  :title="item.serial_number || '--'"
                  @click="choseOne(item)"
                  style="font-size:12px"
                >
                  {{ item.serial_number || "--" }}
                </div>
              </div>
              <div
                class="ellipsisText"
                :title="name(item) || '--'"
                @click="choseOne(item)"
                style="font-size:12px"
              >
                {{ name(item) }}
              </div>
              <div class="type">
                {{ sucai_type(item.sucai_type_id) }}
              </div>
            </div>
            <div class="btn_bar">
              <div class="btn" @click="prew(item)" title="预览">
                <i class="el-icon-view"></i>
              </div>
              <div class="btn" @click="edit(item)" title="编辑"><i class="el-icon-edit"></i></div>
              <div class="btn" @click="delets(item)" title="删除">
                <i class="el-icon-delete"></i>
              </div>
              <!-- <div
                class="btn"
                @click="setTags(item, 0)"
                v-if="item.my_su_cai_label_pivot.length > 0"
              >
                <i class="el-icon-brush" style="transform: rotateX(180deg);"></i>
              </div> -->
              <div class="btn" @click="setTags(item, 1)" title="选择标签">
                <i class="el-icon-price-tag"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-main>

    <editSucai ref="editSucai" />
    <tags ref="tags" @closeTag="closeTag" v-if="showTag" />
    <message-box ref="messageBox" />
    <!-- <detailSucai ref="detailSucai" /> -->
  </el-container>
</template>
<script>
import editSucai from "../editSucai/editSucai.vue";
import tags from "./tags.vue";
import MessageBox from "./messageBox.vue";
/* import detailSucai from "../details/details.vue"; */
export default {
  name: "",
  inject: ["query", "getTagsList"],
  props: {
    tableList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  components: { editSucai, tags, MessageBox },
  data() {
    return {
      /* shows: true, */
      showTag: false,
    };
  },
  created() {},
  mounted() {},
  methods: {
    /* detail(row) {
      console.log(row);
      this.$refs.detailSucai.open({ id: row.id }, val => {
        console.log(val);
        this.query();
      });
    }, */
    name(row) {
      let nameStr = "";
      if (row.sucai_type_id == 4 || row.sucai_type_id == 7) {
        nameStr = row.title;
      } else {
        nameStr = row.title + "." + row.sucai_format;
      }
      return nameStr;
    },

    sucai_type(val) {
      let name = "";
      if (val == 1) {
        name = "图片";
      } else if (val == 2) {
        name = "音频";
      } else if (val == 3) {
        name = "视频";
      } else if (val == 4) {
        name = "文字";
      } else if (val == 5) {
        name = "文件";
      } else if (val == 6) {
        name = "图标";
      } else if (val == 7) {
        name = "链接";
      }
      return name;
    },
    choseOne(item) {
      if (item.selected == 1) {
        item.selected = 0;
      } else {
        item.selected = 1;
      }
      let num = 0;
      let arr = this.tableList.filter(v => {
        return v.selected == 1;
      });
      num = arr.length;
      this.$emit("getCheckedNum", num);
      /* this.shows = false;
      this.$nextTick(() => {
        this.shows = true;
      }); */
    },
    prew(item) {
      console.log(item);
      let type = item.sucai_type_id;
      let src = null;
      if (type == 5) {
        this.$axios
          .get("/base/file/public/preview", {
            attachment_id: item.file_info.attachment_id,
            model_name: "134",
          })
          .then(res => {
            localStorage.setItem("callbackHTML", res);
            window.open("/look.html");
          });
      } else if (type == 7) {
        let judge = item.content.includes("http");
        let url = "";
        if (judge) {
          url = item.content;
        } else {
          url = "http://" + item.content;
        }
        window.open(url);
      } else {
        if (type == 4) {
          // name = "文字";
          src = item.content;
        } else {
          // name = "图标";
          src = item.file_info;
        }
        this.$refs.messageBox.open({ src: src, type: type });
      }
    },
    edit(row) {
      //编辑
      this.$refs.editSucai.open({ id: row.id }, val => {
        console.log(val);
        this.query();
        this.getTagsList();
      });
    },
    delets(row) {
      //删除
      let id = [];
      id.push(row.id);
      this.$confirm("是否删除此条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios.deltJSON("/sucai_manage/user/delete", { id: id }).then(res => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.query();
            this.getTagsList();
          });
        })
        .catch(() => {});
    },
    setTags(row, num) {
      //0是清除 1是新增
      this.showTag = true;
      this.$nextTick(() => {
        let arr = row.my_su_cai_label_pivot.map(i => {
          return i.label_id;
        });
        this.$refs.tags.open({ afterChose: arr }, val => {
          console.log(val);
          let label_list = [];
          val.map(i => {
            let obj = {
              label_id: i.id,
              label_name: i.name,
            };
            return label_list.push(obj);
          });

          let params = {
            id: [row.id],
            label_list: label_list,
          };
          this.$axios.putJSON(`/sucai_manage/user/batch_label`, params).then(res => {
            if (res.code == 200) {
              setTimeout(() => {
                this.$message.success("设置成功");
                this.query();
                this.getTagsList();
              }, 500);
            }
          });
        });
      });
      // }
    },
    closeTag(value) {
      this.showTag = value;
    },
  },
};
</script>
<style lang="scss" scoped>
.box_bar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  .box {
    border: 3px solid #ffffff;
    &:hover {
      .btn_bar {
        display: flex;
      }
    }
    margin-right: 34px;
    margin-bottom: 20px;
    width: 140px;
    height: 192px;

    position: relative;

    .cover {
      background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
      width: 140px;
      height: 140px;
    }
    .number {
      display: flex;
      align-items: center;
    }
    .status {
      font-size: 12px;
      white-space: nowrap;
    }
    .type {
      width: 60px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: radial-gradient(73% 147%, #eadfdf 59%, #ece2df 100%),
        radial-gradient(91% 146%, rgba(255, 255, 255, 0.5) 47%, rgba(0, 0, 0, 0.5) 100%);
      background-blend-mode: screen;
      position: absolute;
      top: 3%;
      right: 3%;
      border-radius: 50px;
      font-size: 12px;
      color: #4387f8;
      box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset,
        rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
      opacity: 0.9;
    }
    .btn_bar {
      display: none;
      width: 120px;
      height: 36px;
      position: absolute;
      bottom: 30%;
      left: 5%;
      // display: flex;
      align-items: center;
      justify-content: space-evenly;
      .btn {
        width: 24px;
        height: 24px;
        border-radius: 7px;
        background-color: #efefef;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
        cursor: pointer;
        i {
          color: #000;
          font-weight: bolder;
          font-size: 18px;
        }
        &:hover {
          background-image: linear-gradient(to top, #bdc2e8 0%, #bdc2e8 1%, #e6dee9 100%);
          i {
            color: azure !important;
          }
        }
      }
    }
  }

  .chosed {
    border-color: #4387f8;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
      rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
      rgba(0, 0, 0, 0.07) 0px 16px 16px;
    &::after {
      position: absolute;
      right: -12px;
      bottom: -12px;
      content: "";
      width: 0;
      height: 0;
      border-width: 12px;
      border-color: #4387f8 transparent transparent transparent;
      border-style: solid;
      transform: rotate(-45deg);
    }
    .icons1 {
      position: absolute;
      right: 0px;
      bottom: -2px;
      color: #fff;
      font-weight: bolder;
      font-size: 12px;
      z-index: 99;
    }
  }
}
</style>
