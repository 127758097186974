/* 预览 */
<template>
  <el-dialog
    :width="type == 2 ? '40%' : '80%'"
    :visible.sync="visible"
    title="预览"
    :close-on-click-modal="false"
  >
    <el-row class="opinion-box">
      <div v-if="type == 4" class="textBox">
        <div v-html="src"></div>
      </div>
      <div v-else-if="type == 2">
        <div class="picBox">
          <audio id="audios" :src="src.image_url" controls></audio>
        </div>
      </div>
      <div v-else-if="type == 3">
        <div class="picBox">
          <video
            id="video"
            :src="src.image_url"
            style="height:560px"
            controls
          ></video>
        </div>
      </div>
      <div v-else>
        <div class="picBox">
          <el-image :src="src.image_url" fit="contain" style="height:560px">
            <div slot="placeholder" class="image-slot">
              加载中
              <span class="dot">...</span>
            </div>
          </el-image>
        </div>
      </div>
    </el-row>
    <!-- <div class="footer-box">
      <el-button size="small" class="footer-btn" @click="visible = false">取消</el-button>
      <el-button size="small" type="primary" class="footer-btn" @click="confirmBtn">
        确定
      </el-button>
    </div> -->
  </el-dialog>
</template>
<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      visible: false,
      callback: null,
      src: null,
      type: null,
    };
  },
  created() {},
  mounted() {},
  methods: {
    open(val, callback) {
      this.src = val.src;
      this.type = val.type;
      this.callback = callback;
      this.visible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.opinion-box {
  padding-left: 20px;
  overflow-y: scroll;
  .input-box {
    margin-left: 12px;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}
/deep/.el-dialog__header {
  padding-bottom: 0px;
}
/deep/.el-dialog__body {
  padding-top: 10px;
}
.picBox {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.textBox {
  min-height: 300px;
  max-height: 600px;
}
</style>
