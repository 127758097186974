var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-drawer',{attrs:{"visible":_vm.drawer,"wrapperClosable":false,"with-header":false,"direction":"rtl","size":"80%","destroy-on-close":true},on:{"update:visible":function($event){_vm.drawer=$event}}},[_c('el-container',{staticStyle:{"height":"100%"}},[_c('el-header',{attrs:{"height":"auto"}},[_c('fw-header',{attrs:{"titles":"默认封面"}})],1),_c('el-main',[_c('el-row',[_c('el-col',{staticStyle:{"margin-top":"10px"},attrs:{"span":24}},[_c('vxe-table',{ref:"xTable1",staticClass:"mytable-scrollbar",attrs:{"stripe":"","highlight-hover-row":"","size":"small","border":false,"align":"center","data":_vm.cover_list,"row-key":""}},[_c('vxe-column',{attrs:{"type":"seq","title":"序号","width":"50","align":"center"}}),_c('vxe-column',{attrs:{"min-width":"100"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("素材类型")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.name}},[_vm._v(" "+_vm._s(row.name || "- -")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"200","align":"center"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("默认封面")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('fwuploadphoto',{staticStyle:{"justify-content":"center","margin-left":"10px"},attrs:{"max":1,"backShow":row.my_sucai_type_default_cover.cover_url ? [row.backShow] : []},on:{"postImgMsg":function (val) { return _vm.postImgMsg(val, row); }}})]}}])}),_c('vxe-column',{attrs:{"min-width":"100"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("创建人")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.created_by}},[_vm._v(" "+_vm._s(row.my_sucai_type_default_cover.created_by || "- -")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"100"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("创建时间")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.created_at}},[_vm._v(" "+_vm._s(row.my_sucai_type_default_cover.created_at || "- -")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"100"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("更新人")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.created_by}},[_vm._v(" "+_vm._s(row.my_sucai_type_default_cover.updated_by || "- -")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"100"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("更新时间")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.created_at}},[_vm._v(" "+_vm._s(row.my_sucai_type_default_cover.updated_at || "- -")+" ")])]}}])})],1)],1)],1)],1),_c('el-footer',{staticStyle:{"margin":"15px 0"}},[_c('el-row',[_c('el-col',{attrs:{"align":"center","span":24}},[_c('span',{staticClass:"chongzhiPlain"},[_c('el-button',{staticStyle:{"width":"88px"},attrs:{"size":"small"},on:{"click":function($event){_vm.drawer = false}}},[_vm._v("取消")])],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }