<template>
  <el-drawer
    :visible.sync="drawer"
    :wrapperClosable="false"
    :with-header="false"
    direction="rtl"
    size="80%"
    :destroy-on-close="true"
  >
    <el-container style="height:100%">
      <el-header height="auto">
        <fw-header titles="素材共享" />
        <!-- <el-alert
          :title="'已选中素材:' + setTitle(selectNames)"
          type="warning"
          :closable="false"
          style="color:#000;font-weight: bolder;"
        ></el-alert> -->
      </el-header>
      <el-main>
        <el-row>
          <el-col :span="24" style="margin:20px 0">
            <span style="color:red">*</span>
            共享接收方:
          </el-col>
          <el-col :span="24">
            <vxe-table
              auto-resize
              class="mytable-scrollbar"
              stripe
              highlight-hover-row
              size="small"
              :border="false"
              :data="tableData"
              ref="xTable1"
              row-key
            >
              <vxe-column type="checkbox" width="60"></vxe-column>
              <vxe-column type="seq" title="序号" width="60"></vxe-column>
              <vxe-column min-width="160" title="名称">
                <template #default="{ row }">
                  <p class="ellipsisText " :title="row.company_name || '--'">
                    {{ row.company_name || "--" }}
                  </p>
                </template>
              </vxe-column>
              <vxe-column min-width="160" title="统一社会信用代码">
                <template #default="{ row }">
                  <p class="ellipsisText " :title="row.company_code || '--'">
                    {{ row.company_code || "--" }}
                  </p>
                </template>
              </vxe-column>
            </vxe-table>
          </el-col>
        </el-row>
      </el-main>
      <el-footer style="margin: 15px 0">
        <el-row>
          <el-col align="center" :span="24">
            <span class="chongzhiPlain">
              <el-button style="width: 88px" size="small" @click="drawer = false">取消</el-button>
              <el-button style="width: 88px" size="small" type="primary" @click="sumbit">
                提交
              </el-button>
            </span>
          </el-col>
        </el-row>
      </el-footer>
    </el-container>
  </el-drawer>
</template>
<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      drawer: false,
      callback: null,
      /* selectNames: [], */
      tableData: [],
    };
  },
  created() {},
  mounted() {},
  methods: {
    open(val, callback) {
      console.log(val);
      /* this.selectNames = val.selectNames.map(i => {
        let str = "";
        if (i.sucai_type_id == 4 || i.sucai_type_id == 7) {
          str = i.title + "(" + i.serial_number + ")";
        } else {
          str = i.title + "(" + i.serial_number + ")" + "." + i.sucai_format;
        }

        return str;
      }); */
      this.getCompanies();
      this.callback = callback;
      this.drawer = true;
    },
    getCompanies() {
      this.$axios.get("/base/user/company").then(res => {
        if (res.code == 200) {
          console.log(res);
          this.tableData = res.data;
        }
      });
    },
    /* setTitle(arr) {
      return arr.join(" ; ");
    }, */
    sumbit() {
      const records = this.$refs.xTable1.getCheckboxRecords();
      if (records.length == 0) {
        return this.$message.warning("请先勾选数据!");
      } else {
        this.callback(records);
        this.drawer = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-drawer__body {
  padding-left: 25px;
  padding-right: 25px;
}
</style>
<style lang="scss"></style>
