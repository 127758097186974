<template>
  <el-drawer
    v-if="drawer"
    :visible.sync="drawer"
    :wrapperClosable="false"
    :with-header="false"
    direction="rtl"
    size="80%"
    id="addSucai"
    :destroy-on-close="true"
  >
    <el-container style="height:100%">
      <el-header height="auto">
        <fw-header titles="标签管理" />
      </el-header>

      <el-main>
        <el-row>
          <el-col :span="24" style="display:flex;justify-content: flex-end;" align="right">
            <el-input
              placeholder="请输入标签名称"
              size="small"
              v-model="name"
              style="width: 246px; margin-right:16px"
            >
              <el-button
                slot="append"
                size="small"
                icon="el-icon-search"
                @click="searchBtn"
              ></el-button>
            </el-input>

            <div class="search-box">
              <el-button type="primary" size="small" @click="addBtn">新增标签</el-button>
            </div>
          </el-col>
        </el-row>

        <div class="forms">
          <vxe-table
            auto-resize
            row-id="id"
            class="mytable-scrollbar choseThis"
            stripe
            highlight-hover-row
            size="small"
            :border="false"
            :data="tableData"
            ref="xTable1"
            id="toolbar_demo3"
            @checkbox-change="selectChangeEvent"
            :checkbox-config="{
              checkMethod: checCheckboxkMethod2,
              checkStrictly: true,
              checkRowKeys: afterChose,
            }"
            row-key
          >
            <vxe-column width="40" v-if="!setAllTags" fixed="left">
              <template #header>
                <vxe-tooltip content="按住后可以上下拖动排序！" enterable>
                  <i class="vxe-icon--question"></i>
                </vxe-tooltip>
              </template>
              <template #default>
                <span class="drag-btn cursor"><i class="vxe-icon--menu"></i></span>
              </template>
            </vxe-column>
            <vxe-column type="checkbox" width="60" v-if="setAllTags"></vxe-column>
            <vxe-column type="seq" title="序号" width="60"></vxe-column>
            <vxe-column width="60" title="启禁用">
              <template #default="{ row }">
                <div>
                  <el-switch
                    @change="
                      val => {
                        changeSwitch(val, row);
                      }
                    "
                    v-model="row.is_enable"
                    :active-value="1"
                    :inactive-value="0"
                    :disabled="row.edit"
                  ></el-switch>
                </div>
              </template>
            </vxe-column>
            <vxe-column title="编号" width="100">
              <template #default="{ row, rowIndex }">
                <div v-if="row.edit">
                  <el-input
                    size="small"
                    style="max-width:140px;"
                    placeholder="请输入"
                    maxlength="4"
                    show-word-limit
                    v-model="row.serial_number"
                  ></el-input>
                </div>
                <p class="ellipsisText" :title="row.describe" v-else>
                  {{ row.serial_number || rowIndex + 1 }}
                </p>
              </template>
            </vxe-column>
            <vxe-column min-width="100">
              <template #header>
                <p>
                  <span style="color:red">*</span>
                  名称
                </p>
              </template>
              <template #default="{ row }">
                <div class="default">
                  <el-tag type="danger" size="mini" v-if="row.is_default == 1">默认</el-tag>
                  <div v-if="row.edit">
                    <el-input
                      size="small"
                      style="max-width:160px;"
                      placeholder="请输入"
                      maxlength="8"
                      show-word-limit
                      v-model="row.name"
                    ></el-input>
                  </div>
                  <p class="ellipsisText" :title="row.describe" v-else>{{ row.name || "--" }}</p>
                </div>
              </template>
            </vxe-column>
            <vxe-column min-width="150">
              <template #header>
                <p>描述</p>
              </template>
              <template #default="{ row }">
                <div v-if="row.edit">
                  <el-input
                    size="small"
                    type="textarea"
                    resize="none"
                    :autosize="{ minRows: 4 }"
                    placeholder="请输入"
                    maxlength="120"
                    show-word-limit
                    v-model="row.describe"
                  ></el-input>
                </div>
                <p class="ellipsisText" :title="row.describe" v-else>{{ row.describe || "--" }}</p>
              </template>
            </vxe-column>
            <vxe-column min-width="140">
              <template #header>
                <p>创建人</p>
                <p>创建时间</p>
              </template>
              <template #default="{ row }">
                <p class="ellipsisText" :title="row.created_by">{{ row.created_by || "--" }}</p>
                <p class="ellipsisText" :title="row.created_at">{{ row.created_at || "--" }}</p>
              </template>
            </vxe-column>
            <vxe-column min-width="140">
              <template #header>
                <p>更新人</p>
                <p>更新时间</p>
              </template>
              <template #default="{ row }">
                <p class="ellipsisText" :title="row.updated_by">{{ row.updated_by || "--" }}</p>
                <p class="ellipsisText" :title="row.updated_at">{{ row.updated_at || "--" }}</p>
              </template>
            </vxe-column>
            <vxe-column width="120" fixed="right">
              <template #header><p>操作</p></template>
              <template #default="{ row }">
                <div v-if="!row.edit">
                  <el-link @click="edit(row)" class="links" :underline="false" type="primary">
                    编辑
                  </el-link>
                  <el-link
                    @click="delets(row)"
                    class="links"
                    :underline="false"
                    type="primary"
                    style="margin:10px"
                  >
                    删除
                  </el-link>
                  <el-link
                    v-if="row.approval_status != 0"
                    class="links"
                    :underline="false"
                    @click="setDefault(row)"
                    type="primary"
                  >
                    设为默认
                  </el-link>
                </div>
                <div v-else>
                  <el-link
                    @click="confirm(row)"
                    class="links"
                    :underline="false"
                    type="primary"
                    style="margin-right:12px"
                  >
                    确定
                  </el-link>
                  <el-link
                    @click="cancel(row, rowIndex)"
                    class="links"
                    :underline="false"
                    type="primary"
                  >
                    取消
                  </el-link>
                </div>
              </template>
            </vxe-column>
          </vxe-table>
        </div>
      </el-main>
      <el-footer style="margin: 30px 0">
        <el-row>
          <el-col align="center" :span="24">
            <span class="chongzhiPlain">
              <el-button style="width: 88px" size="small" @click="closeDrawer">取消</el-button>
              <el-button
                style="width: 88px"
                size="small"
                type="primary"
                @click="sumbit"
                v-if="setAllTags"
              >
                提交
              </el-button>
            </span>
          </el-col>
        </el-row>
      </el-footer>
    </el-container>
  </el-drawer>
</template>

<script>
import Sortable from "sortablejs";
export default {
  name: "",
  components: {},
  inject: ["getTagsList"],
  data() {
    return {
      drawer: false,
      callback: null,
      shows: false,
      name: "",
      tableData: [],
      pages: {
        pageNum: 1,
        pageSize: 10,
        total: 10,
      },
      records: [],
      sortable1: null,
      afterChose: [],
      setAllTags: null, //显示提交和多选框
      is_all: false,
    };
  },
  created() {},
  mounted() {},
  methods: {
    open(val, callback) {
      console.log(val);
      this.afterChose = val.afterChose ? val.afterChose : [];
      if (val.setAllTags) {
        this.setAllTags = false;
        this.query();
      } else {
        this.setAllTags = true;
        this.query2();
      }
      this.is_all = val.is_all ? true : false;
      console.log(this.setAllTags);
      this.callback = callback;
      this.drawer = true;
      this.shows = false;
      this.rowDrop();
      this.$nextTick(() => {
        this.shows = true;
      });
    },

    searchBtn() {
      this.pages.pageNum = 1;
      this.pages.pageSize = 10;
      this.query();
    },
    query() {
      let params = {
        // 导航选项卡的切换的参数
      };
      if (this.name) {
        params.name = this.name;
      }
      this.$axios.postJSON("/sucai_manage/user/label/list", params).then(res => {
        if (res.code == 200) {
          let arr = [];
          if (res.data) {
            res.data.map(i => {
              i["edit"] = false;
              return arr.push(i);
            });
            this.tableData = JSON.parse(JSON.stringify(arr));
          }
        }
      });
    },
    query2() {
      //已选禁用的
      let params = {
        // 导航选项卡的切换的参数
      };
      if (this.name) {
        params.name = this.name;
      }
      this.$axios.postJSON("/sucai_manage/user/label/choice", params).then(res => {
        if (res.code == 200) {
          let arr = [];
          if (res.data) {
            res.data.map(i => {
              i["edit"] = false;
              return arr.push(i);
            });
            this.tableData = JSON.parse(JSON.stringify(arr));
          }
        }
      });
    },
    addBtn() {
      let flag = this.tableData.every(i => i.edit === false);
      if (!flag) {
        this.$message.warning("请完善表格操作");
        return;
      }
      this.tableData.unshift({
        name: "",
        serial_number: "",
        describe: "",
        edit: true,
        is_add: true,
      });
    },

    changeSwitch(val, row) {
      let params = {
        id: row.id,
        is_enable: val,
      };
      this.$axios.putJSON("/sucai_manage/user/label/enable", params).then(res => {
        if (res.code == 200) {
          this.$message.success("操作成功");
          this.query();
        }
      });
    },
    selectChangeEvent({ checked }) {
      const records = this.$refs.xTable1.getCheckboxRecords();
      console.log(this.$refs.xTable1);
      this.records = records;
    },
    checCheckboxkMethod2({ row }) {
      // let num = null;
      // const records = this.$refs.xTable1.getCheckboxRecords();
      // if (this.is_all) {
      //   num = records.length;
      // }
      // num = this.records.length + this.afterChose.length;
      // console.log(num);
      return row.is_enable == 1;
    },
    rowDrop() {
      this.$nextTick(() => {
        const xTable = this.$refs.xTable1;
        console.log(xTable);
        this.sortable1 = Sortable.create(
          xTable.$el.querySelector(".choseThis .body--wrapper>.vxe-table--body tbody"),
          {
            handle: ".drag-btn",
            onEnd: ({ newIndex, oldIndex }) => {
              console.log(newIndex);
              console.log(oldIndex);
              const currRow = this.tableData.splice(oldIndex, 1)[0];
              this.tableData.splice(newIndex, 0, currRow);
              let id = [];
              this.tableData.map(i => {
                return id.push(i.id);
              });
              this.$axios.putJSON("/sucai_manage/user/label/sorting", { id: id }).then(res => {
                if (res.code == 200) {
                  this.$message.success("设置成功");
                  this.query();
                }
              });
            },
          }
        );
      });
    },
    edit(row) {
      row.edit = true;
      row["old_name"] = row.name;
      row["old_serial_number"] = row.serial_number;
      row["old_describe"] = row.describe;
    },
    delets(row) {
      this.$confirm(" 是否删除此条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let id = row.id;
          this.$axios.deltJSON("/sucai_manage/user/label/delete", { id: id }).then(res => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.query();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    confirm(row) {
      if (!row.name) {
        return this.$message.error("请填写标签名称");
      }
      if (row.is_add) {
        console.log(row);
        let params = {
          is_enable: row.is_enable,
          serial_number: row.serial_number,
          name: row.name,
          describe: row.describe,
        };
        console.log(params);
        this.$axios.postJSON("/sucai_manage/user/label/create", params).then(res => {
          if (res.code == 200) {
            this.$message.success("新增成功");
            row.is_add = false;
            this.query();
          }
        });
      } else {
        let params = {
          id: row.id,
          is_enable: row.is_enable,
          serial_number: row.serial_number,
          name: row.name,
          describe: row.describe,
        };
        console.log(params);
        this.$axios.putJSON("/sucai_manage/user/label/update", params).then(res => {
          if (res.code == 200) {
            this.$message.success("编辑成功");
            this.query();
          }
        });
      }
    },
    cancel(row, rowIndex) {
      row.edit = false;
      row.name = row.old_name;
      row.serial_number = row.old_serial_number;
      row.describe = row.old_describe;
      if (row.is_add) {
        this.tableData.splice(rowIndex, 1);
      }
    },
    setDefault(row) {
      this.$axios.putJSON("/sucai_manage/user/label/default", { id: row.id }).then(res => {
        if (res.code == 200) {
          this.$message.success("设置成功");
          this.query();
        }
      });
    },
    sumbit() {
      const records = this.$refs.xTable1.getCheckboxRecords();
      let flag = this.tableData.every(i => i.edit === false);
      if (!flag) {
        this.$message.warning("请完善表格操作");
        return;
      }
      if (records.length == 0) {
        return this.$message.error("请先勾选数据!");
      } else {
        let num = null;
        num = records.length;
        if (num > 5) {
          return this.$message.error("每条数据最多设置5条标签!");
        }
        this.drawer = false;
        this.callback(records);
        this.records = [];
      }
    },
    closeDrawer() {
      // this.drawer = false;
      if (this.setAllTags == false) {
        this.getTagsList();
      }
      this.$emit("closeTag", false);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-drawer__body {
  padding-left: 25px;
  padding-right: 25px;
}
.forms {
  margin-top: 12px;
}
.action-box {
  width: 120px;
  .empha {
    margin: 0 5px;
  }
}
.default {
  display: flex;
  align-items: center;
}
</style>
