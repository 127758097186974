<template>
  <el-drawer
    v-if="drawer"
    :visible.sync="drawer"
    :wrapperClosable="false"
    :with-header="false"
    direction="rtl"
    size="80%"
    id="addSucai"
    :destroy-on-close="true"
  >
    <el-container style="height:100%">
      <el-header height="auto">
        <fw-header titles="上传素材" />
        <fw-title titles="素材类型" style="padding-top:15px" />
        <div style="margin-left:20px">
          <el-radio-group v-model="sucai_type" @change="changeType" class="radio">
            <el-radio v-for="(item, index) in sucai_arr" :key="index" :label="item.value">
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </div>
      </el-header>
      <fw-title titles="基本信息" style="padding-top:15px" />
      <el-main>
        <div style="margin-left:30px">
          <el-form
            ref="ruleForm"
            :rules="rules"
            label-position="left"
            label-width="90px"
            :model="formLabelAlign"
            size="small"
          >
            <el-form-item label="素材编号" prop="serial_number">
              <el-input
                :disabled="isChange"
                clearable
                v-model="formLabelAlign.serial_number"
                style="width: 400px;"
              ></el-input>
            </el-form-item>
            <el-form-item label="素材标题" prop="title">
              <el-input
                clearable
                v-model="formLabelAlign.title"
                style="width: 400px;"
                maxlength="64"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item label="素材标签" prop="label_list">
              <div slot="label">
                <span class="konggefu"></span>
                素材标签
              </div>
              <el-link type="primary" @click="choseTags">标签管理</el-link>
              <div class="tags_bar">
                <div v-for="(item, index) in formLabelAlign.label_list" :key="index">
                  {{ item.label_name }}
                  <i class="el-icon-remove" @click="deletTags(index)"></i>
                </div>
              </div>
            </el-form-item>
            <el-form-item
              label="素材内容"
              prop="file_info"
              v-if="sucai_type == 1 || sucai_type == 6"
            >
              <div class="tips">支持PNG、JPG、GIF格式，单个文件大小不超过5M</div>
              <fwuploadphoto
                v-if="shows"
                style="margin-top: 20px"
                :max="1"
                @postImgMsg="postImgMsg"
                :backShow="
                  formLabelAlign.file_info && formLabelAlign.file_info.image_url
                    ? [formLabelAlign.file_info]
                    : []
                "
              />
            </el-form-item>
            <el-form-item label="素材内容" prop="file_info" v-if="sucai_type == 2">
              <div class="tips">
                支持aac、ac3、acm、amr、flac、m4a、mp3、wav、wma格式，音频文件大小不超过30M
              </div>
              <fw-upload
                type="audio"
                @postMessage="postImgMsg"
                style="width: 400px;"
                :model="formLabelAlign.file_info"
              />
            </el-form-item>
            <el-form-item label="素材内容" prop="file_info" v-if="sucai_type == 3">
              <!-- <div class="tips">
                支持mp3格式，音频文件大小不超过30M
              </div> -->
              <fw-upload
                type="video"
                @postMessage="postImgMsg"
                style="width: 400px;"
                :model="formLabelAlign.file_info"
              />
            </el-form-item>
            <el-form-item label="素材内容" prop="file_info" v-if="sucai_type == 5">
              <fw-upload
                type="file"
                @postMessage="postImgMsg"
                style="width: 400px;"
                :model="formLabelAlign.file_info"
              />
            </el-form-item>
            <el-form-item label="素材内容" prop="content" v-if="sucai_type == 4">
              <!-- <fw-editor
            :msgData="formLabelAlign.content"
            :initialFrameHeight="200"
            @changeMsg="changeMsg"
            style="width: 600px;margin-top: 10px"
          /> -->
              <el-input
                v-model="formLabelAlign.content"
                placeholder="请输入素材内容"
                style="width: 600px;margin-top: 10px"
                :autosize="{ minRows: 3 }"
                resize="none"
                type="textarea"
              ></el-input>
            </el-form-item>
            <el-form-item label="素材内容" prop="content" v-if="sucai_type == 7">
              <el-input
                v-model="formLabelAlign.content"
                placeholder="请输入链接"
                style="width: 680px;margin-top: 10px"
              ></el-input>
            </el-form-item>
            <el-form-item label="素材封面" prop="cover">
              <fwuploadphoto
                v-if="shows"
                style="margin-top: 10px"
                :max="1"
                @postImgMsg="postCover"
                :backShow="
                  formLabelAlign.cover && formLabelAlign.cover.image_url
                    ? [formLabelAlign.cover]
                    : []
                "
              />
            </el-form-item>
          </el-form>
        </div>
      </el-main>
      <el-footer style="margin: 30px 0">
        <el-row style="margin: 30px 0">
          <el-col align="center" :span="24">
            <span class="chongzhiPlain">
              <el-button style="width: 88px" size="small" @click="close">取消</el-button>
            </span>
            <el-button style="width: 88px" size="small" type="primary" @click="sumbit">
              提交
            </el-button>
          </el-col>
        </el-row>
      </el-footer>
    </el-container>
    <fw-cascader ref="fwCascader" />
    <tags ref="tags" @closeTag="closeTag" v-if="showTag" />
  </el-drawer>
</template>
<script>
import tags from "../components/tags.vue";
export default {
  name: "",
  components: { tags },
  inject: ["query"],
  data() {
    return {
      drawer: false, //抽屉弹窗的关闭和打开
      callback: null, //回调
      shows: false, //回显
      sucai_arr: [], //素材类型的集合
      sucai_type: 1, //素材类型的id
      isChange: false, //编号的禁用和启用
      formLabelAlign: {
        //表单
        serial_number: "",
        title: "",
        label_list: [], //标签数据
        file_info: {
          attachment_id: 0,
          full_name: "",
          image_save_url: "",
          image_url: "",
          size: 0,
          suffix: "",
        },
        cover: {
          attachment_id: 0,
          full_name: "",
          image_save_url: "",
          image_url: "",
          size: 0,
          suffix: "",
        },
        content: "", //素材内容 文字、链接直接传字符串
      },
      rules: {
        serial_number: [{ required: true, message: "请输入素材编号", trigger: "blur" }],
        title: [{ required: true, message: "请输入素材标题", trigger: "blur" }],
        content: [{ required: true, message: "请输入素材内容", trigger: "blur" }],
        file_info: [{ required: true, message: "请选择素材内容", trigger: "change" }],
        cover: [{ required: true, message: "请选择素材封面", trigger: "change" }],
      },
      showTag: false,
      submiting: false
    };
  },
  created() {},
  mounted() {},
  methods: {
    open(val, callback) {
      this.callback = callback;
      this.drawer = true;
      this.getSucai_type();
      this.getDefaultTag();
      this.getNumber();
      this.getCover();
      this.shows = false;
      this.$nextTick(() => {
        this.shows = true;
      });
    },
    getDefaultTag() {
      //素材默认标签
      this.$axios.get("/sucai_manage/public/my_sucai_default_label").then(res => {
        if (res.code == 200) {
          console.log(res.data);
          if (res.data) {
            let i = res.data;
            let obj = {
              label_id: i.id,
              label_name: i.name,
            };
            return (this.formLabelAlign.label_list[0] = JSON.parse(JSON.stringify(obj)));
          }
        }
      });
    },
    getSucai_type() {
      //素材类型
      this.$axios.get("/sucai_manage/sucai_type/list").then(res => {
        let array = [];
        if (res.data && res.data.length) {
          res.data.map(it => {
            array.push({
              value: it.id,
              label: it.name,
            });
          });
        }
        this.sucai_arr = array;
      });
    },
    changeType(val) {
      console.log(this.formLabelAlign.file_info);
      this.formLabelAlign.file_info = {
        attachment_id: 0,
        full_name: "",
        image_save_url: "",
        image_url: "",
        size: 0,
        suffix: "",
      };
      if (this.sucai_type == 1 || this.sucai_type == 6) {
        this.formLabelAlign.cover = {
          attachment_id: 0,
          full_name: "",
          image_save_url: "",
          image_url: "",
          size: 0,
          suffix: "",
        };
        console.log(this.formLabelAlign.cover);
        this.shows = false;
        this.$nextTick(() => {
          this.shows = true;
        });
      } else {
        this.getCover();
        console.log(this.formLabelAlign.cover);
      }
    },
    getCover() {
      this.$axios
        .get("/sucai_manage/user/cover/detail", {
          sucai_type_id: this.sucai_type,
        })
        .then(res => {
          console.log(res);
          if (res.code == 200) {
            if (res.data) {
              let obj = {
                attachment_id: 0,
                full_name: "",
                image_save_url: "",
                image_url: res.data.cover_url,
                size: 0,
                suffix: "",
              };
              this.formLabelAlign.cover = JSON.parse(JSON.stringify(obj));
              console.log(this.formLabelAlign.cover);
              this.shows = false;
              this.$nextTick(() => {
                this.shows = true;
              });
            } else {
              this.formLabelAlign.cover = {
                attachment_id: 0,
                full_name: "",
                image_save_url: "",
                image_url: "",
                size: 0,
                suffix: "",
              };
              this.shows = false;
              this.$nextTick(() => {
                this.shows = true;
              });
            }
          }
        });
    },
    getNumber(val) {
      //获取编号
      this.$axios.get("/sucai_manage/user/number").then(res => {
        console.log(res);
        if (res.code == 200) {
          if (res.data) {
            this.isChange = false;
            this.formLabelAlign.serial_number = res.data.number;
          }
        }
      });
    },
    postImgMsg(val) {
      console.log(val);
      console.log(this.formLabelAlign.file_info);

      this.formLabelAlign.title = val[0]?.full_name || "";

      if (this.sucai_type == 1 || this.sucai_type == 6) {
        this.formLabelAlign.file_info = val[0];
        this.formLabelAlign.cover = val[0];
        this.shows = false;
        this.$nextTick(() => {
          this.shows = true;
        });
      } else {
        this.formLabelAlign.file_info = val;
      }
    },
    postCover(val) {
      //封面
      console.log(val);
      this.formLabelAlign.cover = val[0];
    },
    choseTags() {
      this.showTag = true;
      this.$nextTick(() => {
        let arr = this.formLabelAlign.label_list.map(i => {
          return i.label_id;
        });
        this.$refs.tags.open({ afterChose: arr }, val => {
          console.log(val);
          val.map(i => {
            let obj = {
              label_id: i.id,
              label_name: i.name,
            };
            return this.formLabelAlign.label_list.push(obj);
          });
          console.log(this.formLabelAlign.label_list);
        });
      });
    },
    closeTag(value) {
      this.showTag = value;
    },
    deletTags(index) {
      this.$confirm("是否删除此标签?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.formLabelAlign.label_list.splice(index, 1);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    sumbit() {
      if (this.submiting) return;
      this.$refs.ruleForm.validate(valid => {
        if (
          this.sucai_type == 1 ||
          this.sucai_type == 2 ||
          this.sucai_type == 3 ||
          this.sucai_type == 5 ||
          this.sucai_type == 6
        ) {
          if (!this.formLabelAlign.file_info.image_url) {
            this.$message.error("请选择素材内容");
            return;
          }
        } else {
          if (!this.formLabelAlign.content) {
            this.$message.error("请选择素材内容");
            return;
          }
        }

        if (this.formLabelAlign.cover.image_url == null) {
          this.$message.error("请选择素材封面");
          return;
        }
        if (valid) {
          console.log(this.formLabelAlign);
          let params = {
            sucai_type_id: this.sucai_type,
            ...this.formLabelAlign,
          };
          console.log(params);
          this.submiting = true;
          this.$axios.postJSON(`/sucai_manage/user/create`, params).then(res => {
            if (res.code == 200) {
              console.log(res);
              this.$message.success("上传成功");
              setTimeout(() => {
                this.drawer = false;
                this.sucai_type = 1;
                this.formLabelAlign = {
                  serial_number: "",
                  title: "",
                  label_list: [],
                  file_info: {
                    attachment_id: 0,
                    full_name: "",
                    image_save_url: "",
                    image_url: "",
                    size: 0,
                    suffix: "",
                  },
                  cover: {
                    attachment_id: 0,
                    full_name: "",
                    image_save_url: "",
                    image_url: "",
                    size: 0,
                    suffix: "",
                  },
                  content: "", //素材内容 文字、链接直接传字符串
                };
                this.query();
                this.submiting = false
              }, 500);
            }
          }).catch(error => {
            this.submiting = false;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    close() {
      this.drawer = false;
      this.sucai_type = 1;
      this.formLabelAlign = {
        serial_number: "",
        title: "",
        label_list: [],
        file_info: {
          attachment_id: 0,
          full_name: "",
          image_save_url: "",
          image_url: "",
          size: 0,
          suffix: "",
        },
        cover: {
          attachment_id: 0,
          full_name: "",
          image_save_url: "",
          image_url: "",
          size: 0,
          suffix: "",
        },
        content: "", //素材内容 文字、链接直接传字符串
      };
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-drawer__body {
  padding-left: 25px;
}
.tips {
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
}
.tags_bar {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  div {
    margin: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    padding: 0 20px;
    min-width: 40px;
    border-radius: 15px;
    color: #f74848;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    position: relative;
    i {
      color: #f74848;
      position: absolute;
      top: 1%;
      right: 2%;
    }
  }
}
</style>
<style lang="scss">
.el-main::-webkit-scrollbar {
  display: none;
}
</style>
